<template>
  <div class="wrap">
    <van-nav-bar :title="$t('page.financial')">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
      <template #right>
        <div
          @click="handleLinkPage('FinancialRecord')"
          style="
            padding: calc(6rem / 16) calc(12rem / 16);
            background: #fff;
            color: var(--theme);
            border-radius: 4px;
            font-size: calc(14rem / 16);
          "
        >
          {{ $t("newAdd.Record") }}
        </div>
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="info">
        <div class="left">
          <div class="title">{{ $t("totalDeposit") }}</div>
          <div class="value">{{ balance || "0.00" }}</div>
        </div>
        <div class="right">
          <div class="title">{{ $t("totalRevenue") }}</div>
          <div class="value">{{ userInfo.yuebao_income_amount || "0.00" }}</div>
        </div>
      </div>
      <div class="btns">
        <div class="into" @click="handleLinkPage('TransferInto')">
          <img src="../../assets/financial/gold.png" alt="" />
          <div class="text">{{ $t("btn.transferInto") }}</div>
        </div>
        <div class="out" @click="handleLinkPage('TransferOut')">
          <img src="../../assets/financial/me_gold.png" alt="" />
          <div class="text">{{ $t("btn.transferOut") }}</div>
        </div>
      </div>
      <div class="list_wrap">
        <FinancialRecord />
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Icon, Toast } from "vant";
import { getToken } from "@/utils/tools";
import { $post, $get } from "../../utils/request";
import FinancialRecord from "./components/financialRecordCom.vue";
export default {
  components: { [NavBar.name]: NavBar, [Icon.name]: Icon, FinancialRecord },
  data() {
    return {
      reqNum: 0,
      userInfo: {},
      balance: "0.00",
    };
  },
  created() {
    Toast.loading();
    this.getUserInfo();
    this.getFinancialBalance();
  },
  methods: {
    // 页面跳转
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    getUserInfo() {
      this.reqNum++;
      this.$api.getUserInfo(
        (res) => {
          this.reqNum--;
          this.userInfo = res;
        },
        (err) => {
          this.reqNum--;
        }
      );
    },
    async getFinancialBalance() {
      this.reqNum++;
      try {
        const res = await $get("/yuebao/balance");
        this.reqNum--;
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          const { balance, rate } = data;
          this.balance = balance;
          console.log(this.balance);
          // this.rate = rate;
          return;
        }
        Toast(msg);
      } catch (error) {
        this.reqNum--;
      }
    },
  },
  watch: {
    reqNum(value) {
      if (value === 0) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f7f8fc;
}
.content {
  height: calc(120rem / 16);
  background: linear-gradient(315deg, #a78e6f, #d5bd9d);
  border-radius: 0 0 calc(16rem / 16) calc(16rem / 16);
  position: relative;
  .info {
    height: calc(100rem / 16);
    padding: 0 calc(20rem / 16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      text-align: center;
      color: #fff;
      .title {
        font-size: calc(14rem / 16);
        font-weight: 400;
        opacity: 0.8;
      }
      .value {
        font-size: calc(30rem / 16);
        line-height: calc(40rem / 16);
      }
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(16rem / 16);
    > div {
      display: flex;
      padding: calc(10rem / 16) calc(15rem / 16);
      background: #fff;
      border-radius: calc(8rem / 16);
      box-shadow: 0 2px 12px 0 rgba(114, 114, 154, 10%);
      img {
        width: calc(30rem / 16);
        height: calc(27rem / 16);
      }
      .text {
        font-weight: 600;
        font-size: calc(14rem / 16);
        color: #636f85;
        margin-left: calc(10rem / 16);
        line-height: calc(27rem / 16);
      }
    }
  }
}
</style>